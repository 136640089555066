import logServerside from "app/_actions/logServerside";
import { PublicEnv } from "./PublicEnv";

/* interface LogParams {
   level: "none" | "error" | "warn" | "info" | "debug" | "verbose";
   message?: string;
   data?: object;
} */
export interface LevelInt {
   none: 0;
   error: 1;
   warn: 2;
   info: 3;
   debug: 4;
   verbose: 5;
}
const levelInt = {
   none: 0,
   error: 1,
   warn: 2,
   info: 3,
   debug: 4,
   verbose: 5
} as LevelInt;

export type ILogParams = Array<string | object | Array<any> | undefined | null>;

export default class ILog {
   static e(...args: ILogParams) {
      if (!PublicEnv.LogLevel) {
         throw new Error("PublicEnv.LogLevel is undefined");
      }
      const isLevel = levelInt["error"] <= levelInt[PublicEnv.LogLevel];
      if (isLevel) {
         console.error(...args);
      }
   }
   static w(...args: ILogParams) {
      if (!PublicEnv.LogLevel) {
         throw new Error("PublicEnv.LogLevel is undefined");
      }
      const isLevel = levelInt["warn"] <= levelInt[PublicEnv.LogLevel];
      if (isLevel) {
         console.warn(...args);
      }
   }
   static i(...args: ILogParams) {
      if (!PublicEnv.LogLevel) {
         throw new Error("PublicEnv.LogLevel is undefined");
      }
      const isLevel = levelInt["info"] <= levelInt[PublicEnv.LogLevel];
      if (isLevel) {
         console.info(...args);
      }
   }
   static d(...args: ILogParams) {
      if (!PublicEnv.LogLevel) {
         throw new Error("PublicEnv.LogLevel is undefined");
      }
      const isLevel = levelInt["debug"] <= levelInt[PublicEnv.LogLevel];
      if (isLevel) {
         console.log("debug", ...args);
         // args.forEach((arg) => {
         //    console.dir(arg);
         // });
      }
   }
   static v(...args: ILogParams) {
      if (!PublicEnv.LogLevel) {
         throw new Error("PublicEnv.LogLevel is undefined");
      }
      const isLevel = levelInt["verbose"] <= levelInt[PublicEnv.LogLevel];
      if (isLevel) {
         console.log("verbose", ...args);
      }
   }
   static async s(...args: ILogParams) {
      if (!PublicEnv.LogLevel) {
         throw new Error("PublicEnv.LogLevel is undefined");
      }

      // else if (PublicEnv.NodeEnv !== "development") {
      //    return;
      // }
      await logServerside(JSON.stringify(args));
   }
   static n(...args: ILogParams) {
      if (!PublicEnv.LogLevel) {
         throw new Error("PublicEnv.LogLevel is undefined");
      }
      const isLevel = levelInt["none"] <= levelInt[PublicEnv.LogLevel];
      if (isLevel) {
         console.log("none", ...args);
      }
   }
}
