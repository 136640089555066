import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { R } from "app/(platform)/meeting-room/_types";
const initialState: R.Room.RoomState = {
   empathyCafeModalOpen: false,
   empathyCafe: false,
   autoMute: false,
   breakoutsActive: false,
   meetingWorkspace: undefined,
   meetingTitle: "",
   meetingDescription: "",
   meetingDate: null,
   meetingPassword: undefined,
   meetingAdjourned: false,
   localHost: false,
   localCoHost: false,
   titleError: "",
   descriptionError: "",
   dateError: "",
   passwordError: "",
   livekitUrl: "",
   livekitToken: null,
   hostModalError: "",
   canChooseListener: false,
   editingAgendaItem: false, // Whether or not the user is editing an agenda item
   refreshInit: true,
   audioAllowed: true,
   audioEnabled: true,
   videoEnabled: true,
   viewMode: "grid",
   localParticipant: undefined,
   itemsPerPage: 5,
   workspaceInviteToken: undefined
};

export const meetingSlice = createSlice({
   name: "meeting",
   initialState,
   reducers: {
      setWorkspaceInviteToken: (state, action: PayloadAction<string | undefined>) => {
         state.workspaceInviteToken = action.payload;
      },
      adjournMeeting: (state, action: PayloadAction<boolean>) => {
         state.meetingAdjourned = action.payload;
         state.meetingAdjourned = true;
         // state.agendaMenuOpen = true;
      },
      updateLocalHost: (state, action: PayloadAction<boolean>) => {
         state.localHost = action.payload;
      },
      updateLocalCoHost: (state, action: PayloadAction<boolean>) => {
         state.localCoHost = action.payload;
      },
      updateMeeting: (state, action: PayloadAction<R.Room.RoomState>) => {
         state.meetingTitle = action.payload.meetingTitle;
         state.meetingDescription = action.payload.meetingDescription;
         state.meetingPassword = action.payload.meetingPassword;
         state.meetingAdjourned = action.payload.meetingAdjourned;

         state.empathyCafe = action.payload.empathyCafe;
      },
      setEditingAgendaItem: (state, action: PayloadAction<boolean>) => {
         state.editingAgendaItem = action.payload;
      },
      setEmpathyCafe: (state, action: PayloadAction<boolean>) => {
         state.empathyCafe = action.payload;
      },
      setEmpathyCafeModalOpen: (state, action: PayloadAction<boolean>) => {
         state.empathyCafeModalOpen = action.payload;
      },
      setAudioEnabled: (state, action: PayloadAction<boolean>) => {
         state.audioEnabled = action.payload;
      },
      setVideoEnabled: (state, action: PayloadAction<boolean>) => {
         state.videoEnabled = action.payload;
      },
      toggleAudioAllowed: (state, action: PayloadAction<boolean>) => {
         state.audioAllowed = action.payload;
      },
      setViewMode: (state, action: PayloadAction<"grid" | "listening" | "spotlight">) => {
         state.viewMode = action.payload as "grid" | "listening" | "spotlight";
      },
      setMeetingPassword: (state, action: PayloadAction<string>) => {
         state.meetingPassword = action.payload;
      },
      setMeetingWorkspace: (state, action: PayloadAction<string>) => {
         state.meetingWorkspace = action.payload;
      },

      setLivekitToken: (state, action: PayloadAction<string | null>) => {
         state.livekitToken = action.payload;
      },
      setItemsPerPage: (state, action: PayloadAction<number>) => {
         state.itemsPerPage = action.payload;
      }
   }
});

export const refreshInit = createSelector(
   (state: R.Room.RoomState) => state,
   (state) => state.refreshInit
);

export const selectIsLocalHost = createSelector(
   (state: R.Room.RoomState) => state,
   (state) => state.localHost
);

export const meetingActions = meetingSlice.actions;
