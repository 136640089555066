import {
   PayloadAction,
   createEntityAdapter,
   createSelector,
   createSlice
   // omit other imports
} from "@reduxjs/toolkit";
import { R } from "app/(platform)/meeting-room/_types";
import { selectListeningRequestsByTurnId } from "./listeningRequestSlice";
import { selectActiveRound, selectActiveRoundByBreakoutId } from "./roundSlice";
import { IRootState } from "app/_contexts/ReduxProvider";

const turnAdapter = createEntityAdapter({
   selectId: (turn: R.Turn.Turn) => turn.id,
   sortComparer: (a, b) => {
      if (a?.turnStartTime && b?.turnStartTime) {
         return a.turnStartTime - b.turnStartTime;
      } else {
         return 0;
      }
   }
});

const initialState = turnAdapter.getInitialState({
   status: "idle",
   error: null
});

export const turnSlice = createSlice({
   name: "turn",
   initialState,
   reducers: {
      updateTurn(state, action: PayloadAction<R.Turn.UpdateOne>) {
         const {
            id,
            roundId,
            speakerIdentity,
            listenerIdentity,
            speakerHeard,
            listenerAccepted,

            turnComplete,
            turnActive,
            turnPaused,
            intermissionStartTime,
            turnStartTime,
            turnEndTime,
            turnDuration,
            turnPausedTime,
            breakoutId
         } = action.payload;
         const existingTurn = state.entities[id];
         if (existingTurn) {
            (existingTurn.roundId = roundId),
               (existingTurn.speakerIdentity = speakerIdentity),
               (existingTurn.listenerIdentity = listenerIdentity),
               (existingTurn.speakerHeard = speakerHeard),
               (existingTurn.listenerAccepted = listenerAccepted),
               (existingTurn.turnComplete = turnComplete),
               (existingTurn.turnActive = turnActive),
               (existingTurn.turnPaused = turnPaused),
               (existingTurn.intermissionStartTime = intermissionStartTime),
               (existingTurn.turnStartTime = turnStartTime),
               (existingTurn.turnEndTime = turnEndTime),
               (existingTurn.turnDuration = turnDuration),
               (existingTurn.turnPausedTime = turnPausedTime),
               (existingTurn.breakoutId = breakoutId);
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      acceptListeningRequest(state, action: PayloadAction<R.Turn.AcceptLR>) {
         const { id, listenerIdentity, listenerName } = action.payload;
         const existingTurn = state.entities[id];

         if (existingTurn) {
            existingTurn.listenerIdentity = listenerIdentity;
            existingTurn.listenerName = listenerName;
            existingTurn.listenerAccepted = true;
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      endTurn(state, action: PayloadAction<R.Turn.IdOnly>) {
         const id = action.payload.id;
         const existingTurn = state.entities[id];

         if (existingTurn) {
            existingTurn.turnComplete = true;
            existingTurn.turnActive = false;
            existingTurn.turnPaused = false;
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      pauseTurn(state, action: PayloadAction<R.Turn.Pause>) {
         const id = action.payload.id;
         const existingTurn = state.entities[id];
         if (existingTurn) {
            existingTurn.turnPaused = true;
            existingTurn.turnPausedTime = action.payload.turnPausedTime;
            existingTurn.turnResumeTime = action.payload.turnResumeTime;
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      resumeTurn(state, action: PayloadAction<R.Turn.Resume>) {
         const id = action.payload.id;
         const existingTurn = state.entities[id];
         //
         if (existingTurn) {
            existingTurn.turnPaused = action.payload.turnPaused;
            existingTurn.turnResumeTime = action.payload.turnResumeTime;
            existingTurn.turnPausedTime = action.payload.turnPausedTime;
            (existingTurn.turnStartTime = action.payload.turnStartTime),
               (existingTurn.turnEndTime = action.payload.turnEndTime),
               (existingTurn.intermissionStartTime = action.payload.intermissionStartTime);
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      startTurn(state, action: PayloadAction<R.Turn.Start>) {
         const { id, intermissionStartTime, turnStartTime, turnEndTime } = action.payload;
         const existingTurn = state.entities[id];
         if (existingTurn) {
            existingTurn.turnActive = true;
            existingTurn.turnStartTime = turnStartTime;
            existingTurn.turnEndTime = turnEndTime;
            existingTurn.intermissionStartTime = intermissionStartTime;
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      queueTurn(state, action: PayloadAction<R.Turn.IdOnly>) {
         const id = action.payload.id;
         const existingTurn = state.entities[id];
         if (existingTurn) {
            existingTurn.turnActive = true;
            existingTurn.turnPaused = false;
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      voidTurn(state, action: PayloadAction<R.Turn.IdOnly>) {
         const id = action.payload.id;
         const existingTurn = state.entities[id];
         if (existingTurn) {
            existingTurn.listenerAccepted = false;
            /*   existingTurn.listenerIdentity = null;
            existingTurn.listenerName = null; */
            existingTurn.turnActive = false;
            existingTurn.turnPaused = false;
            existingTurn.void = true;
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      /* confirmTurn(state, action: PayloadAction<R.Turn.IdOnly>) {
         const id = action.payload.id;
         const existingTurn = state.entities[id];
         if (existingTurn) {
            existingTurn.void = false;
         } else if(action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      }, */
      cancelListener(state, action: PayloadAction<R.Turn.IdOnly>) {
         const id = action.payload.id;
         const existingTurn = state.entities[id];
         if (existingTurn) {
            existingTurn.listenerAccepted = false;
            existingTurn.listenerIdentity = null;
            existingTurn.listenerName = null;
         } else if (action?.payload?.fallback) {
            turnActions.addTurn(action.payload.fallback);
         }
      },
      addTurn: turnAdapter.addOne,
      updateOneTurn: turnAdapter.updateOne,
      removeTurn: turnAdapter.removeOne,
      addTurns: turnAdapter.addMany,
      updateManyTurns: turnAdapter.updateMany,
      removeManyTurns: turnAdapter.removeMany,
      upsertTurn: turnAdapter.upsertOne,
      upsertTurns: turnAdapter.upsertMany,
      removeAllTurns: turnAdapter.removeAll,
      setAllTurns: turnAdapter.setAll,
      setOneTurn: turnAdapter.setOne,
      setManyTurns: turnAdapter.setMany
   }
});

export const turnActions = turnSlice.actions;

export const selectTurnsByRoundId = createSelector(
   // return all rounds that match the agendaItemId
   (state: IRootState) => state.turn.entities,
   (_: IRootState, roundId?: string) => roundId,
   (turns, roundId) => {
      return Object.values(turns).filter((turn) => turn?.roundId === roundId && turn?.void === false) as R.Turn.Turn[];
   }
);

// Warning: could be unstable to use.
export const selectActiveTurnByParticipantIdentity = createSelector(
   // return all turns that match the participantIdentity
   (state: IRootState) => state,
   (_: IRootState, params: { participantIdentity?: string; breakoutId?: string }) => params,
   (state, { participantIdentity, breakoutId }) => {
      const turns = selectTurnByBreakoutId(state, { breakoutId });
      if (turns) {
         return Object.values(turns).filter((turn) => (turn?.speakerIdentity === participantIdentity || turn?.listenerIdentity === participantIdentity) && turn?.turnActive && !turn?.void);
      }
   }
);
export const selectTurnByBreakoutId = createSelector(
   // return all turns that match the breakoutId
   (state: IRootState) => state.turn.entities,
   (_: IRootState, params: { breakoutId?: string }) => params,
   (turns, { breakoutId }) => {
      return Object.values(turns).filter((turn) => turn?.breakoutId === breakoutId && turn?.void === false);
   }
);
export const selectActiveTurnBySpeakerIdentity = createSelector(
   // return all turns that match the participantIdentity
   (state: IRootState) => state.turn.entities,
   (_: IRootState, params: { speakerIdentity?: string; breakoutId?: string }) => params,
   (turns, { speakerIdentity, breakoutId }) => {
      return Object.values(turns).find((turn) => turn?.speakerIdentity === speakerIdentity && turn?.turnActive && !turn?.void && turn?.breakoutId === breakoutId);
   }
);
export const selectTurnByListenerIdentity = createSelector(
   // return all turns that match the participantIdentity
   (state: IRootState) => state.turn.entities,
   (_: IRootState, params: { listenerIdentity?: string; breakoutId?: string }) => params,

   (turns, { listenerIdentity, breakoutId }) => {
      return Object.values(turns).find((turn) => turn?.listenerIdentity === listenerIdentity && !turn?.void && turn?.breakoutId === breakoutId);
   }
);

export const selectActiveTurn = createSelector(
   // return the first active turn
   //(state: RootState) => state.turn.entities,
   (state: IRootState) => state,
   (_: IRootState, breakoutId?: string) => breakoutId,
   (state, breakoutId) => {
      const activeRound = selectActiveRoundByBreakoutId(state, breakoutId);
      const roundTurns = selectTurnsByRoundId(state, activeRound?.id);
      if (activeRound) {
         const activeRoundTurn = Object.values(roundTurns).find((turn) => turn?.turnActive && !turn?.void && turn?.breakoutId === breakoutId);

         return activeRoundTurn;
      }
   }
);

export const selectIncompleteTurnBySpeakerIdentity = createSelector(
   // return the first turn that matches the speakerIdentity
   (state: IRootState) => state.turn.entities,
   (_: IRootState, params: { speakerIdentity: string | null | undefined; breakoutId?: string }) => params,

   (turns, { speakerIdentity, breakoutId }) => {
      return Object.values(turns).find((turn) => turn?.speakerIdentity === speakerIdentity && !turn?.turnComplete && !turn?.void && turn?.breakoutId === breakoutId);
   }
);

export const selectIncompleteDependentTurnsAndListeningRequestsBySpeakerIdentity = createSelector(
   // return all incomplete turns that depend on the void turn
   (state: IRootState) => state,
   (_: IRootState, params: { targetIdentity?: string; breakoutId?: string }) => params,

   (state, { targetIdentity, breakoutId }) => {
      // Array of participants who have an incomplete turn. Remove identities from it when they are found in a valid turn
      let unsortedParticipants = [] as { identity: string }[];
      let voidableParticipants: string[] = [];
      let validParticipants: string[] = [];
      let voidTurns: R.Turn.Turn[] = [];
      let validTurns: R.Turn.Turn[] = [];
      const roundId = selectActiveRound(state, breakoutId)?.id;
      const targetSpeakerTurn = selectIncompleteTurnBySpeakerIdentity(state, {
         speakerIdentity: targetIdentity,
         breakoutId
      });
      const incompleteTurns = selectIncompleteTurnsByRoundId(state, roundId);
      const targetValidTurn = selectTurnByListenerIdentity(state, { listenerIdentity: targetIdentity, breakoutId });
      const turns = selectTurnsByRoundId(state, roundId);
      turns.forEach((turn) => {
         turn?.speakerIdentity &&
            unsortedParticipants.push({
               identity: turn?.speakerIdentity
            });
      });
      let count = turns.length;
      targetIdentity && voidableParticipants.push(targetIdentity);
      if (targetSpeakerTurn) {
         voidTurns.push(targetSpeakerTurn);
      }
      if (targetSpeakerTurn && targetSpeakerTurn.listenerIdentity) {
         voidableParticipants.push(targetSpeakerTurn.listenerIdentity);
      }

      if (targetValidTurn) {
         validTurns.push(targetValidTurn);
         validParticipants.push(targetValidTurn.speakerIdentity);
         unsortedParticipants.filter((participant) => {
            if (participant.identity === targetValidTurn.speakerIdentity) {
               return true;
            } else {
               return true;
            }
         });
      }
      unsortedParticipants.filter((participant) => {
         if (participant.identity === targetIdentity || participant.identity === targetSpeakerTurn?.listenerIdentity) {
            return false;
         } else {
            return true;
         }
      });

      while (unsortedParticipants.length > 0 && count > 0) {
         //
         incompleteTurns.forEach((turn) => {
            // If the turn's speaker is already void, add it to the voidableTurns array and add the turn's listener to the voidableParticipants array
            if (turn && voidableParticipants.includes(turn?.speakerIdentity) && turn?.listenerIdentity) {
               voidTurns.push(turn);
               voidableParticipants.push(turn?.listenerIdentity);
               unsortedParticipants.filter((participant) => {
                  if (participant.identity === turn?.speakerIdentity || participant.identity === turn.listenerIdentity) {
                     return false;
                  } else {
                     return true;
                  }
               });
            }
            // If the turn's listener is already validated, add it to the validTurns array, and add the turn's speaker to the validParticipants array
            if (turn && turn?.listenerIdentity && targetValidTurn && validParticipants.includes(turn.listenerIdentity)) {
               validTurns.push(turn);
               validParticipants.push(turn.speakerIdentity);
               unsortedParticipants.filter((participant) => {
                  if (participant.identity === turn.speakerIdentity) {
                     return false;
                  } else {
                     return true;
                  }
               });
            }
         });
         count--;
      }
      // Also return voidListeningRequests for the voidTurns
      let voidListeningRequests: R.ListeningRequest.ListeningRequest[] = [];
      voidTurns.forEach((turn) => {
         const listeningRequests = selectListeningRequestsByTurnId(state, turn.id);
         listeningRequests.forEach((request) => {
            voidListeningRequests.push(request);
         });
      });
      //
      return { voidTurns, voidListeningRequests };
   }
);

export const selectIncompleteTurnByListenerIdentity = createSelector(
   // return the first turn that matches the speakerIdentity
   (state: IRootState) => state.turn.entities,
   (_: IRootState, params: { listenerIdentity?: string; breakoutId?: string }) => params,
   (turns, { listenerIdentity, breakoutId }) => {
      const breakoutTurns = Object.values(turns).filter((turn) => turn?.breakoutId === breakoutId);
      return Object.values(breakoutTurns).find((turn) => turn?.listenerIdentity === listenerIdentity && !turn?.turnComplete && !turn?.void);
   }
);

export const selectReflectiveTurnBySpeakerIdentity = createSelector(
   (state: IRootState) => state,
   (_: IRootState, params: { speakerIdentity?: string; breakoutId?: string }) => params,
   (state, { speakerIdentity, breakoutId }) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, activeRound?.id);
      if (activeRound && activeRound.empathyMode === "reflective") {
         const turn = Object.values(turns).find((turn) => turn?.speakerIdentity === speakerIdentity && !turn?.turnComplete && !turn?.void);
         //
         return turn;
      }
   }
);

export const selectIncompleteTurnsOfActiveRoundByTimeSort = createSelector(
   // return the first turn that matches the speakerIdentity, and is in the active round
   (state: IRootState) => state,
   (_: IRootState, breakoutId?: string) => breakoutId,
   (state, breakoutId) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, activeRound?.id);
      const activeRoundTurns = Object.values(turns).filter((turn) => turn?.roundId === activeRound?.id && !turn?.turnComplete && !turn?.turnActive && !turn?.void);

      const sortedTurns = Object.values(activeRoundTurns).sort((a, b) => {
         if (a?.turnClaimTime && b?.turnClaimTime) {
            return a.turnClaimTime - b.turnClaimTime;
         } else {
            return 0;
         }
      });
      return sortedTurns;
   }
);

export const selectCompleteTurnsOfActiveRoundByTimeSort = createSelector(
   // return the first turn that matches the speakerIdentity, and is in the active round
   (state: IRootState) => state,
   (_: IRootState, breakoutId?: string) => breakoutId,
   (state, breakoutId) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, activeRound?.id);
      const activeRoundTurns = Object.values(turns).filter((turn) => turn?.roundId === activeRound?.id && turn?.turnComplete && !turn?.void);

      const sortedTurns = Object.values(activeRoundTurns).sort((a, b) => {
         if (a?.turnClaimTime && b?.turnClaimTime) {
            return a.turnClaimTime - b.turnClaimTime;
         } else {
            return 0;
         }
      });
      return sortedTurns;
   }
);

export const selectActiveRoundTurnsByTimeSort = createSelector(
   // return the first turn that matches the speakerIdentity, and is in the active round
   (state: IRootState) => state,
   (_: IRootState, breakoutId?: string) => breakoutId,
   (state, breakoutId) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, activeRound?.id);
      const activeRoundTurns = Object.values(turns).filter((turn) => turn?.roundId === activeRound?.id && !turn?.void);

      const sortedTurns = Object.values(activeRoundTurns).sort((a, b) => {
         if (a?.turnClaimTime && b?.turnClaimTime) {
            return a.turnClaimTime - b.turnClaimTime;
         } else {
            return 0;
         }
      });
      return sortedTurns;
   }
);

/* export const selectNullTurnsByRoundId = createSelector(
   (state: RootState) => state.turn.entities,
   (_: RootState, roundId?: string) => roundId,
   (turns, roundId) => {
      return Object.values(turns).filter((turn) => turn?.roundId === roundId && turn?.void === null);
   }
); */

export const selectFirstTurnClaimsByTimeSort = createSelector(
   // return the first turn that matches the speakerIdentity, and is in the active round
   (state: IRootState) => state,
   (_: IRootState, params: { roundId?: string; breakoutId?: string }) => params,
   (state, { roundId, breakoutId }) => {
      //  const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, roundId);
      const activeRoundTurns = Object.values(turns).filter((turn) => turn?.roundId === roundId && turn?.firstTurn && !turn?.turnComplete && turn?.turnActive && turn?.void === false);

      return Object.values(activeRoundTurns).sort((a, b) => {
         if (a?.turnClaimTime && b?.turnClaimTime) {
            return a.turnClaimTime - b.turnClaimTime;
         } else {
            return 0;
         }
      });
   }
);

export const selectActiveRoundTurnBySpeakerIdentity = createSelector(
   // return the first turn that matches the speakerIdentity, and is in the active round
   (state: IRootState) => state,
   (_: IRootState, params: { speakerIdentity?: string; breakoutId?: string }) => params,
   (state, { speakerIdentity, breakoutId }) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, activeRound?.id);
      if (activeRound && turns && speakerIdentity) {
         return Object.values(turns).find((turn) => turn?.roundId === activeRound.id && turn?.speakerIdentity === speakerIdentity && !turn?.void);
      }
   }
);

export const selectTurnWithoutListener = createSelector(
   // return an incomplete turn that has no listener
   (state: IRootState) => state,
   (_: IRootState, params: { participantIdentity?: string; breakoutId?: string }) => params,
   (state, { participantIdentity, breakoutId }) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, activeRound?.id);
      if (turns) {
         return Object.values(turns).find((turn) => findWithoutListener(turn));
      }

      function findWithoutListener(turn?: R.Turn.Turn): boolean | undefined {
         return turn?.speakerIdentity === participantIdentity && turn?.listenerAccepted === false && turn?.void === false;
      }
   }
);

export const selectAbandonedReflectiveTurn = createSelector(
   // return an incomplete turn that has no listener
   (state: IRootState) => state,
   (_: IRootState, params: { breakoutId?: string }) => params,
   (state, { breakoutId }) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, activeRound?.id);
      if (turns) {
         return Object.values(turns).find((turn) => findWithoutListener(turn));
      }

      function findWithoutListener(turn?: R.Turn.Turn): boolean | undefined {
         return turn?.listenerAccepted === false && turn?.void === false && turn?.turnComplete === true;
      }
   }
);

export const selectCompleteTurnsByRoundId = createSelector(
   // return all turns that match the roundId and are complete
   (state: IRootState) => state.turn.entities,
   (_: IRootState, roundId?: string) => roundId,
   (turns, roundId) => {
      return Object.values(turns).filter((turn) => turn?.roundId === roundId && turn?.turnComplete && !turn?.void);
   }
);

export const selectIncompleteTurnsByRoundId = createSelector(
   // return all turns that match the roundId and are incomplete
   (state: IRootState) => state.turn.entities,
   (_: IRootState, roundId?: string) => roundId,
   (turns, roundId) => {
      return Object.values(turns).filter((turn) => turn?.roundId === roundId && !turn?.turnComplete && !turn?.void);
   }
);

export const selectIncompleteAndInactiveTurnsByRoundId = createSelector(
   // return all turns that match the roundId and are incomplete
   (state: IRootState) => state.turn.entities,
   (_: IRootState, roundId?: string) => roundId,
   (turns, roundId) => {
      return Object.values(turns).filter((turn) => turn?.roundId === roundId && !turn?.turnComplete && !turn?.turnActive && !turn?.void);
   }
);

export const selectNextSpeaker = createSelector(
   // return a listener of a complete turn, when the listener is not a speaker in one of the turns
   (state: IRootState) => state,
   (_: IRootState, params: { breakoutId?: string }) => params,
   (state, { breakoutId }) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const completeTurns = selectCompleteTurnsByRoundId(state, activeRound?.id);
      const incompleteTurns = selectIncompleteTurnsByRoundId(state, activeRound?.id);
      if (activeRound && completeTurns && incompleteTurns) {
         const speakers = completeTurns.map((turn) => turn?.speakerIdentity);
         const listeners = completeTurns.map((turn) => turn?.listenerIdentity);
         const nextSpeaker = listeners.find((listener) => {
            if (listener) {
               // this DOES NOT handle the case where the last speaker needs to select a listener. In that case, they should be able to choose any listener.
               return !speakers.includes(listener);
            } else {
               return false;
            }
         });

         return nextSpeaker;
      }
   }
);

export const selectParticipantsWhoDoNotHaveATurn = createSelector(
   // return a list of participants who do not have a turn in the active round
   (state: IRootState) => state,
   (_: IRootState, params: { participants?: string[]; breakoutId?: string }) => params,
   (state, { participants, breakoutId }) => {
      const activeRound = selectActiveRound(state, breakoutId);
      const turns = selectTurnsByRoundId(state, activeRound?.id);
      if (activeRound && turns && participants) {
         const speakerIdentities = turns.map((turn) => turn?.speakerIdentity);
         const participantsWhoDoNotHaveATurn = participants.filter((identity) => !speakerIdentities.includes(identity));
         //
         return participantsWhoDoNotHaveATurn;
      } else {
         return [];
      }
   }
);

// Export the customized selectors for this adapter using `getSelectors`
export const {
   selectAll: selectAllTurns,
   selectById: selectTurnById,
   selectIds: selectTurnIds,
   selectEntities: selectTurnEntities

   // Pass in a selector that returns the posts slice of state
} = turnAdapter.getSelectors<IRootState>((state) => state.turn);
