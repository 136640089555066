import getConfig from "next/config";
import { LevelInt } from "./Log";

const config = getConfig();
const { NODE_ENV } = config?.publicRuntimeConfig || { NODE_ENV: "development" };
export class PublicEnv {
   static AccordionOpenOverride = process.env.NEXT_PUBLIC_ACCORDION_OPEN_OVERRIDE;
   static AllowDefaultProfileEdit = process.env.NEXT_PUBLIC_ALLOW_DEFAULT_PROFILE_EDIT;
   static ApplicationBaseHost = process.env.NEXT_PUBLIC_APPLICATION_HOST;
   static ApplicationBaseURL = process.env.NEXT_PUBLIC_APPLICATION_BASE_URL;
   static ApplicationEnvironment = process.env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT;
   static BuildId = process.env.BUILD_ID; // provided by next
   static DirectusAuthenticatedRoleId = process.env.NEXT_PUBLIC_DIRECTUS_AUTHENTICATED_ROLE_ID;
   static DirectusHost = process.env.NEXT_PUBLIC_DIRECTUS_HOST; // Formerly NEXT_PUBLIC_API_URL
   static HocuspocusBaseURL = process.env.NEXT_PUBLIC_HOCUSPOCUS_BASE_URL;
   static LivekitBaseURL = process.env.NEXT_PUBLIC_LIVEKIT_BASE_URL;
   static LivekitHostURL = process.env.NEXT_PUBLIC_LIVEKIT_HOST_URL;
   static LogLevel = process.env.NEXT_PUBLIC_LOG_LEVEL as unknown as keyof LevelInt;
   static MigrationDuration = process.env.NEXT_PUBLIC_MIGRATION_DURATION;
   static MigrationMessage = process.env.NEXT_PUBLIC_MIGRATION_MESSAGE;
   static MigrationTimestamp = process.env.NEXT_PUBLIC_MIGRATION_TIMESTAMP;
   static NodeEnv = NODE_ENV;
   static PublicFlowUUIDJson = process.env.NEXT_PUBLIC_FLOW_UUID_JSON;
   static StripeKey = process.env.NEXT_PUBLIC_STRIPE_KEY;
   static DirectusAPIKey: any;
   static MaxRoomOverride = process.env.NEXT_PUBLIC_MAX_ROOM_OVERRIDE;
   static HomePageDocumentIds = process.env.NEXT_PUBLIC_HOME_PAGE_DOCUMENT_IDS;
}
