import {
   PayloadAction,
   createEntityAdapter,
   createSelector,
   createSlice
   // omit other imports
} from "@reduxjs/toolkit";
import { R } from "app/(platform)/meeting-room/_types";
import { selectAllAgendaItems } from "./agendaItemSlice";
import { IRootState } from "app/_contexts/ReduxProvider";

const roundAdapter = createEntityAdapter({
   selectId: (round: R.Round.Round) => round.id,
   sortComparer: (a, b) => {
      if (a?.created && b?.created) {
         return a.created - b.created;
      } else {
         return 0;
      }
   }
});

const initialState = roundAdapter.getInitialState({
   status: "idle",
   error: null
});

export const roundSlice = createSlice({
   name: "round",
   initialState,
   reducers: {
      updateRound(state, action: PayloadAction<R.Round.UpdateOne>) {
         const { id, agendaItemId, roundComplete, roundActive, roundPaused, empathyMode, strictTime, speakerTime, intermissionTime, breakoutId } = action.payload;
         const existingRound = state.entities[id];
         if (existingRound) {
            (existingRound.agendaItemId = agendaItemId),
               (existingRound.roundComplete = roundComplete),
               (existingRound.roundActive = roundActive),
               (existingRound.roundPaused = roundPaused),
               (existingRound.empathyMode = empathyMode),
               (existingRound.strictTime = strictTime),
               (existingRound.speakerTime = speakerTime),
               (existingRound.intermissionTime = intermissionTime),
               (existingRound.breakoutId = breakoutId);
         } else if (action?.payload?.fallback) {
            roundActions.addRound(action.payload.fallback);
         }
      },
      startRound(state, action: PayloadAction<R.Round.IdOnly>) {
         const id = action.payload.id;
         const existingRound = state.entities[id];

         if (existingRound) {
            existingRound.roundActive = true;
         } else if (action?.payload?.fallback) {
            roundActions.addRound(action.payload.fallback);
         }
      },
      addRound: roundAdapter.addOne,
      updateOneRound: roundAdapter.updateOne,
      removeRound: roundAdapter.removeOne,
      addRounds: roundAdapter.addMany,
      updateManyRounds: roundAdapter.updateMany,
      removeManyRounds: roundAdapter.removeMany,
      upsertRound: roundAdapter.upsertOne,
      upsertRounds: roundAdapter.upsertMany,
      removeAllRounds: roundAdapter.removeAll,
      setAllRounds: roundAdapter.setAll,
      setOneRound: roundAdapter.setOne,
      setManyRounds: roundAdapter.setMany,
      // We want to pause an active round for the timer. The round remains active until it is complete.
      pauseRound(state, action: PayloadAction<R.Round.IdOnly>) {
         const id = action.payload.id;
         const existingRound = state.entities[id];

         if (existingRound) {
            existingRound.roundPaused = true;
         } else if (action?.payload?.fallback) {
            roundActions.addRound(action.payload.fallback);
         }
      },
      resumeRound(state, action: PayloadAction<R.Round.IdOnly>) {
         const id = action.payload.id;
         const existingRound = state.entities[id];
         if (existingRound) {
            existingRound.roundPaused = false;
         } else if (action?.payload?.fallback) {
            roundActions.addRound(action.payload.fallback);
         }
      },
      endRound(state, action: PayloadAction<R.Round.IdOnly>) {
         const id = action.payload.id;
         const existingRound = state.entities[id];
         if (existingRound) {
            existingRound.roundComplete = true;
            existingRound.roundActive = false;
            existingRound.roundPaused = false;
         } else if (action?.payload?.fallback) {
            roundActions.addRound(action.payload.fallback);
         }
      }
   }
});

export const roundActions = roundSlice.actions;

export const selectRoundsByAgendaItemId = createSelector(
   // return all rounds that match the agendaItemId
   (state: IRootState) => selectAllRounds(state),
   (_: IRootState, agendaItemId?: string) => agendaItemId,
   (rounds, agendaItemId) => {
      return Object.values(rounds).filter((round) => round?.agendaItemId === agendaItemId);
   }
);

export const selectIncompleteRoundsByAgendaItemId = createSelector(
   // return all rounds that match the agendaItemId
   (state: IRootState) => selectAllRounds(state),
   (_: IRootState, agendaItemId?: string) => agendaItemId,
   (rounds, agendaItemId) => {
      return Object.values(rounds).filter((round) => round?.agendaItemId === agendaItemId && !round?.roundComplete && !round?.roundActive);
   }
);

export const selectActiveRoundByAgendaItemId = createSelector(
   // return all rounds that match the agendaItemId
   (state: IRootState) => selectAllRounds(state),
   (_: IRootState, agendaItemId?: string) => agendaItemId,
   (rounds, agendaItemId) => {
      const itemRounds = Object.values(rounds).filter((round) => round?.agendaItemId === agendaItemId);
      return itemRounds.find((round) => round?.roundActive);
   }
);

export const selectActiveRoundByBreakoutId = createSelector(
   // return all rounds that match the agendaItemId
   (state: IRootState) => selectAllRounds(state),
   (_: IRootState, breakoutId?: string) => breakoutId,
   (rounds, breakoutId) => {
      const itemRounds = Object.values(rounds).filter((round) => round?.breakoutId === breakoutId);
      return itemRounds.find((round) => round?.roundActive === true);
   }
);

export const selectActiveRound = createSelector(
   // return the round that is active
   (state: IRootState) => selectAllRounds(state),
   (_: IRootState, breakoutId?: string) => breakoutId,
   (rounds, breakoutId) => {
      return Object.values(rounds).find((round) => round?.roundActive && round?.breakoutId === breakoutId);
   }
);

export const selectNextInactiveRound = createSelector(
   // return the agenda item that is active, but the round is not active
   (state: IRootState) => selectAllRounds(state),
   (state: IRootState) => selectAllAgendaItems(state),
   (_: IRootState, breakoutId?: string) => breakoutId,
   (rounds, agendaItems, breakoutId) => {
      const activeAgendaItem = Object.values(agendaItems).find((agendaItem) => agendaItem?.itemActive && agendaItem?.breakoutId === breakoutId);
      if (activeAgendaItem) {
         return Object.values(rounds).find((round) => round?.agendaItemId === activeAgendaItem.id && !round?.roundActive && !round?.roundComplete);
      }
      return undefined;
   }
);

// Export the customized selectors for this adapter using `getSelectors`
export const {
   selectAll: selectAllRounds,
   selectById: selectRoundsById,
   selectIds: selectRoundsIds,
   selectEntities: selectRoundEntities

   // Pass in a selector that returns the posts slice of state
} = roundAdapter.getSelectors<IRootState>((state) => state.round);

/* export const selectPostsByUser = createSelector(
  [selectAllAgendaItems, (state, userId) => userId],
  (agendaItems, userId) => agendaItems.filter((item) => item.user === userId)
); */
