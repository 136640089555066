import { createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { R } from "app/(platform)/meeting-room/_types";
import { IRootState } from "app/_contexts/ReduxProvider";
const chatMessageAdapter = createEntityAdapter({
   selectId: (chatMessage: R.ChatMessage.ChatMessage) => chatMessage.id,
   sortComparer: (a, b) => (a.createdAt > b.createdAt ? 1 : -1)
});

const initialState = chatMessageAdapter.getInitialState({
   status: "idle",
   error: null
});

export const chatMessageSlice = createSlice({
   name: "chatMessage",
   initialState,
   reducers: {
      // updateChatMessage(state, action: PayloadAction<R.ChatMessage.UpdateOne>) {
      //    const { id, facilitatorIdentity, participants, active } = action.payload;
      //    const existingChatMessage = state.entities[id];
      //    if (existingChatMessage) {
      //       existingChatMessage.facilitatorIdentity = facilitatorIdentity;

      //       existingChatMessage.participants = participants;
      //       existingChatMessage.active = active;
      //    } else if (action?.payload?.fallback) {
      //       chatMessageActions.addChatMessage(action.payload.fallback);
      //    }
      // },

      addChatMessage: chatMessageAdapter.addOne,
      updateOneChatMessage: chatMessageAdapter.updateOne,
      removeChatMessage: chatMessageAdapter.removeOne,
      addChatMessages: chatMessageAdapter.addMany,
      updateManyChatMessages: chatMessageAdapter.updateMany,
      removeManyChatMessages: chatMessageAdapter.removeMany,
      upsertChatMessage: chatMessageAdapter.upsertOne,
      upsertChatMessages: chatMessageAdapter.upsertMany,
      removeAllChatMessages: chatMessageAdapter.removeAll,
      setAllChatMessages: chatMessageAdapter.setAll,
      setOneChatMessage: chatMessageAdapter.setOne,
      setManyChatMessages: chatMessageAdapter.setMany
   }
});

export const selectChatMessagesByBreakoutId = createSelector(
   // return all rounds that match the agendaItemId
   (state: IRootState) => selectAllChatMessages(state),
   (_: IRootState, breakoutId?: string) => breakoutId,
   (messages, breakoutId) => {
      return Object.values(messages).filter((message) => message?.breakoutId === breakoutId);
      // const sorted = filtered.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));
      // return sorted;
   }
);

export const chatMessageActions = chatMessageSlice.actions;

export const {
   selectAll: selectAllChatMessages,
   selectById: selectChatMessageById,
   selectIds: selectChatMessageIds,
   selectEntities: selectChatMessageEntities
} = chatMessageAdapter.getSelectors<IRootState>((state) => state.chatMessage);
