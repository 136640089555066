import { PayloadAction, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { R } from "app/(platform)/meeting-room/_types";
import { IRootState } from "app/_contexts/ReduxProvider";
import ILog from "app/_lib/global/Log";
const breakoutAdapter = createEntityAdapter({
   selectId: (breakout: R.Breakout.BreakoutRoom) => breakout.id
   // sortComparer: (a, b) => a.id - b.id
});

const initialState = breakoutAdapter.getInitialState({
   status: "idle",
   error: null
});

export const breakoutSlice = createSlice({
   name: "breakout",
   initialState,
   reducers: {
      addParticipants(state, action: PayloadAction<R.Breakout.AddParticipants>) {
         const { id, participants } = action.payload;
         const existingBreakout = state.entities[id];

         if (existingBreakout) {
            const { participants: existingParticipants } = existingBreakout;
            const newParticipants = participants.filter((p) => !existingParticipants.find((ep) => ep === p));
            const uniqueParticipants = new Set([...existingParticipants, ...newParticipants]);
            // create an array from the set
            existingBreakout.participants = Array.from(uniqueParticipants);
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      removeParticipant: (state, action: PayloadAction<R.Breakout.IdAndIdentityOnly>) => {
         const id = action.payload.id;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            const newParticipants = existingBreakout.participants.filter((p) => p !== action.payload.identity);
            existingBreakout.participants = newParticipants;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      updateBreakout(state, action: PayloadAction<R.Breakout.UpdateOne>) {
         const { id, facilitatorIdentity, participants, active } = action.payload;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.facilitatorIdentity = facilitatorIdentity;

            existingBreakout.participants = participants;
            existingBreakout.active = active;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      setActive(state, action: PayloadAction<R.Breakout.IdAndIsOnly>) {
         const { id, is } = action.payload;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.active = is;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      setBreakoutFacilitator(state, action: PayloadAction<R.Breakout.IdAndIdentityOnly>) {
         const { id, identity } = action.payload;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.facilitatorIdentity = identity;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      setClosed(state, action: PayloadAction<R.Breakout.IdAndIsOnly>) {
         const { id, is } = action.payload;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.closed = is;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      startBreakout(state, action: PayloadAction<R.Breakout.IdOnly>) {
         const id = action.payload.id;
         const existingBreakout = state.entities[id];

         if (existingBreakout) {
            existingBreakout.active = true;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      addBreakout: breakoutAdapter.addOne,
      updateOneBreakout: breakoutAdapter.updateOne,
      removeBreakout: breakoutAdapter.removeOne,
      addBreakouts: breakoutAdapter.addMany,
      updateManyBreakouts: breakoutAdapter.updateMany,
      removeManyBreakouts: breakoutAdapter.removeMany,
      upsertBreakout: breakoutAdapter.upsertOne,
      upsertBreakouts: breakoutAdapter.upsertMany,
      removeAllBreakouts: breakoutAdapter.removeAll,
      setAllBreakouts: breakoutAdapter.setAll,
      setOneBreakout: breakoutAdapter.setOne,
      setManyBreakouts: breakoutAdapter.setMany,
      // We want to pause an active round for the timer. The round remains active until it is complete.

      endBreakout(state, action: PayloadAction<R.Breakout.IdOnly>) {
         const id = action.payload.id;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.active = false;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      setActiveHostIdentity: (state, action: PayloadAction<R.Breakout.IdAndIdentityOnly>) => {
         const id = action.payload.id;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.activeHostIdentity = action.payload.identity;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      setActiveSpeakerIdentity: (state, action: PayloadAction<R.Breakout.IdAndIdentityOnly>) => {
         const id = action.payload.id;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.latestSpeakerIdentity = action.payload.identity;
            // If the active speaker is the same person, then we want to keep the previous active speaker
            existingBreakout.previousSpeakerIdentity =
               action.payload.identity === existingBreakout.latestSpeakerIdentity && !!existingBreakout.previousSpeakerIdentity
                  ? existingBreakout.previousSpeakerIdentity
                  : existingBreakout.latestSpeakerIdentity;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      setScreenShareIdentity: (state, action: PayloadAction<R.Breakout.IdAndIdentityOnly>) => {
         const id = action.payload.id;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.screenShareIdentity = action.payload.identity;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },

      startAgenda: (state, action: PayloadAction<R.Breakout.IdOnly>) => {
         const existingBreakout = state.entities[action?.payload?.id];
         if (existingBreakout) {
            existingBreakout.agendaStarted = true;

            existingBreakout.agendaPaused = false;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
         //  (state.agendaStarted = true), (state.agendaPaused = false);
      },
      pauseAgenda: (state, action: PayloadAction<R.Breakout.IdOnly>) => {
         const existingBreakout = state.entities[action?.payload?.id];
         if (existingBreakout) {
            existingBreakout.agendaPaused = true;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      resumeAgenda: (state, action: PayloadAction<R.Breakout.IdOnly>) => {
         const existingBreakout = state.entities[action?.payload?.id];
         if (existingBreakout) {
            existingBreakout.agendaPaused = false;
         } else if (action?.payload?.fallback) {
            breakoutActions.addBreakout(action.payload.fallback);
         }
      },
      setScreenSyncPath(state, action: PayloadAction<{ id: string; screenSyncPath: string }>) {
         const { id, screenSyncPath } = action.payload;
         const existingBreakout = state.entities[id];
         if (existingBreakout) {
            existingBreakout.screenSyncPath = screenSyncPath;
         }
      }
   }
});

export const breakoutActions = breakoutSlice.actions;

export const selectBreakoutByParticipantIdentity = createSelector(
   // return one breakout that has the participantIdentity
   (state: IRootState) => state.breakout.entities,
   (_: IRootState, targetIdentity?: string) => targetIdentity,
   (breakouts, targetIdentity) => {
      // TODO HP: bool returns undefined
      const bool = Object.values(breakouts).find((breakout) => Boolean(breakout?.participants.find((p) => p === targetIdentity)));
      ILog.v("selectBreakoutByParticipantIdentity", { breakouts, targetIdentity, bool });
      return Object.values(breakouts).find((breakout) => Boolean(breakout?.participants.find((p) => p === targetIdentity)));
   }
);

export const selectDuplicateBreakoutsByParticipantIdentity = createSelector(
   // return one breakout that has the participantIdentity
   (state: IRootState) => state.breakout.entities,
   (_: IRootState, targetIdentity?: string) => targetIdentity,
   (breakouts, targetIdentity) => {
      // TODO HP: bool returns undefined
      const bool = Object.values(breakouts).filter((breakout) => Boolean(breakout?.participants.find((p) => p === targetIdentity)));
      ILog.v("selectDuplicateBreakoutsByParticipantIdentity", { breakouts, targetIdentity, bool });
      const b = Object.values(breakouts).filter((breakout?: R.Breakout.BreakoutRoom) => {
         if (breakout !== undefined && targetIdentity !== undefined) {
            // return breakout?.participants.find((p) => p.identity === targetIdentity) && !breakout?.mainBreakout;
            return Boolean(!breakout?.mainBreakout && breakout?.participants.find((p) => p === targetIdentity));
         } else {
            return false;
         }
      });
      return b;
   }
);

export const selectDuplicateWithinBreakoutByParticipantIdentity = createSelector(
   // return one breakout that has the participantIdentity
   (state: IRootState) => state.breakout.entities,
   (_: IRootState, targetIdentity?: string) => targetIdentity,
   (breakouts, targetIdentity) => {
      // TODO HP: bool returns undefined
      const bool = Object.values(breakouts).filter((breakout) => Boolean(breakout?.participants.find((p) => p === targetIdentity)));
      ILog.v("selectDuplicateWithinBreakoutByParticipantIdentity", { breakouts, targetIdentity, bool });
      const b = Object.values(breakouts).filter((breakout?: R.Breakout.BreakoutRoom) => {
         if (breakout !== undefined && targetIdentity !== undefined) {
            return Boolean(breakout?.participants?.filter((p) => p === targetIdentity)?.length > 1);
         } else {
            return false;
         }
      });
      return b;
   }
);

export const selectIsLocalBreakout = createSelector(
   // return a list of participants who do not have a turn in the active round
   (state: IRootState) => state,
   (_: IRootState, params: { localIdentity?: string; breakoutId?: string }) => params,
   (state, { localIdentity, breakoutId }) => {
      if (breakoutId) {
         const breakout = selectBreakoutById(state, breakoutId);
         const localParticipant = breakout?.participants.find((p) => p === localIdentity);
         return localParticipant ? true : false;
      } else {
         return undefined;
      }
   }
);

export const selectIsBreakoutFacilitator = createSelector(
   // return a list of participants who do not have a turn in the active round
   (state: IRootState) => state,
   (_: IRootState, params: { targetIdentity?: string; breakoutId?: string }) => params,
   (state, { targetIdentity, breakoutId }) => {
      if (breakoutId) {
         const breakout = selectBreakoutById(state, breakoutId);
         const isFacilitator = breakout?.facilitatorIdentity === targetIdentity;
         return isFacilitator;
      } else if (targetIdentity) {
         const breakout = selectBreakoutByParticipantIdentity(state, targetIdentity);
         const isFacilitator = breakout?.facilitatorIdentity === targetIdentity;
         return isFacilitator;
      } else {
         return undefined;
      }
   }
);

export const selectChildBreakouts = createSelector(
   // return a list of participants who do not have a turn in the active round
   (state: IRootState) => state,

   (state) => {
      const breakouts = selectAllBreakouts(state);
      const childBreakouts = breakouts.filter((b) => !b.mainBreakout);
      return childBreakouts;
   }
);

export const selectCountActiveChildBreakouts = createSelector(
   // return a list of participants who do not have a turn in the active round
   (state: IRootState) => state,

   (state) => {
      const breakouts = selectAllBreakouts(state);
      return breakouts.filter((b) => !b.mainBreakout && b.active).length;
   }
);

export const selectMainBreakout = createSelector(
   // return the mainBreakout
   (state: IRootState) => state,

   (state) => {
      const breakouts = selectAllBreakouts(state);
      return breakouts.find((b) => b.mainBreakout);
   }
);

export const selectActiveBreakouts = createSelector(
   (state: IRootState) => state,

   (state) => {
      const breakouts = selectAllBreakouts(state);
      return breakouts.filter((b) => {
         return b.closed !== true;
      });
   }
);

export const selectAllBreakoutIdentities = createSelector(
   // return all participants from all selectActiveBreakouts
   (state: IRootState) => state,
   (state) => {
      const breakouts = selectActiveBreakouts(state);
      const participants = breakouts.flatMap((b) => b.participants);
      return participants;
   }
);

export const selectScreenSyncPath = createSelector(
   // return a list of participants who do not have a turn in the active round
   (state: IRootState) => state,
   (_: IRootState, params: { breakoutId?: string }) => params,
   (state, { breakoutId }) => {
      if (breakoutId) {
         const breakout = selectBreakoutById(state, breakoutId);
         return breakout?.screenSyncPath;
      } else {
         return undefined;
      }
   }
);

export const {
   selectAll: selectAllBreakouts,
   selectById: selectBreakoutById,
   selectIds: selectBreakoutIds,
   selectEntities: selectBreakoutEntities
} = breakoutAdapter.getSelectors<IRootState>((state) => state.breakout);
