"use client";
import {
   PayloadAction,
   createEntityAdapter,
   createSelector,
   createSlice
   // omit other imports
} from "@reduxjs/toolkit";

import { R } from "app/(platform)/meeting-room/_types";
import { selectParticipantsWhoDoNotHaveATurn } from "./turnSlice";
import { IRootState } from "app/_contexts/ReduxProvider";

const listeningRequestAdapter = createEntityAdapter({
   selectId: (listeningRequest: R.ListeningRequest.ListeningRequest) => listeningRequest.id
   //  sortComparer: (a, b) => a.sort - b.sort
});

const initialState = listeningRequestAdapter.getInitialState({
   status: "idle",
   error: null
});

export const listeningRequestSlice = createSlice({
   name: "listeningRequest",
   initialState,
   reducers: {
      updateListeningRequest(state, action: PayloadAction<R.ListeningRequest.UpdateOne>) {
         const { id, turnId, speakerIdentity, listenerIdentity, listenerAccepted, timeAccepted, fallback } = action.payload;
         const existingListeningRequest = state.entities[id];
         if (existingListeningRequest) {
            (existingListeningRequest.turnId = turnId),
               (existingListeningRequest.speakerIdentity = speakerIdentity),
               (existingListeningRequest.listenerIdentity = listenerIdentity),
               (existingListeningRequest.listenerAccepted = listenerAccepted),
               (existingListeningRequest.timeAccepted = timeAccepted);
         } else if (fallback) {
            listeningRequestActions.addListeningRequest(fallback as R.ListeningRequest.ListeningRequest);
         }
      },
      addListeningRequest: listeningRequestAdapter.addOne,
      updateOneListeningRequest: listeningRequestAdapter.updateOne,
      removeListeningRequest: listeningRequestAdapter.removeOne,
      addListeningRequests: listeningRequestAdapter.addMany,
      updateManyListeningRequests: listeningRequestAdapter.updateMany,
      removeManyListeningRequests: listeningRequestAdapter.removeMany,
      upsertListeningRequest: listeningRequestAdapter.upsertOne,
      upsertListeningRequests: listeningRequestAdapter.upsertMany,
      removeAllListeningRequests: listeningRequestAdapter.removeAll,
      setAllListeningRequests: listeningRequestAdapter.setAll,
      setOneListeningRequest: listeningRequestAdapter.setOne,
      setManyListeningRequests: listeningRequestAdapter.setMany,
      acceptListeningRequest(state, action: PayloadAction<R.ListeningRequest.Accept>) {
         const { id, listenerIdentity, listenerName, timeAccepted, fallback } = action.payload;
         const existingListeningRequest = state.entities[id];
         if (existingListeningRequest) {
            (existingListeningRequest.listenerAccepted = true),
               (existingListeningRequest.listenerIdentity = listenerIdentity),
               (existingListeningRequest.listenerName = listenerName),
               (existingListeningRequest.timeAccepted = timeAccepted);
         } else if (fallback) {
            listeningRequestActions.addListeningRequest(fallback as R.ListeningRequest.ListeningRequest);
         }
      },
      voidListeningRequest(state, action: PayloadAction<R.ListeningRequest.Void>) {
         const { id, fallback } = action.payload;
         const existingListeningRequest = state.entities[id];
         if (existingListeningRequest) {
            existingListeningRequest.void = true;
         } else if (fallback) {
            listeningRequestActions.addListeningRequest(fallback as R.ListeningRequest.ListeningRequest);
         }
      },
      declineListeningRequest(state, action: PayloadAction<R.ListeningRequest.Decline>) {
         const { id, fallback } = action.payload;
         const existingListeningRequest = state.entities[id];
         if (existingListeningRequest) {
            existingListeningRequest.listenerAccepted = false;
         } else if (fallback) {
            listeningRequestActions.addListeningRequest(fallback as R.ListeningRequest.ListeningRequest);
         }
      }
   }
});

export const localParticipantListeningRequests = createSelector(
   (state: IRootState) => selectAllListeningRequest(state),
   (_: IRootState, participantIdentity: string | undefined) => participantIdentity,
   (listeningRequests, participantIdentity) => {
      if (participantIdentity) {
         return Object.values(listeningRequests).find(
            (listeningRequest?: R.ListeningRequest.ListeningRequest) =>
               listeningRequest?.listenerAccepted === undefined && listeningRequest?.listenerIdentity === participantIdentity && listeningRequest?.void === false
         ) as R.ListeningRequest.ListeningRequest;
      } else {
         return undefined;
      }
   }
);

export const selectListeningRequestsByTurnId = createSelector(
   (state: IRootState) => selectAllListeningRequest(state),
   (_: IRootState, turnId?: string) => turnId,
   (listeningRequests, turnId) => {
      return Object.values(listeningRequests).filter(
         (listeningRequest?: R.ListeningRequest.ListeningRequest) => listeningRequest?.turnId === turnId && listeningRequest?.void === false
      ) as R.ListeningRequest.ListeningRequest[];
   }
);

// Return the participants who have not received a listening request for this turnId, and they do not have a turn in the activeRound.
export const selectReflectiveListenersByTurn = createSelector(
   (state: IRootState) => state,
   (
      _: IRootState,
      params: {
         targetTurn?: R.Turn.Turn;
         speakerIdentity?: string;
         participants?: string[];
      }
   ) => params,
   // (_: RootState, participants) => participants,
   (state, secondArg) => {
      const { targetTurn, speakerIdentity, participants } = secondArg;
      const listeningRequests = selectAllListeningRequest(state);
      if (targetTurn && speakerIdentity && participants) {
         const remoteParticipants = participants.filter((participant) => {
            return participant !== speakerIdentity;
         });
         const participantsNoTurn = selectParticipantsWhoDoNotHaveATurn(state, {
            participants: remoteParticipants,
            breakoutId: targetTurn.breakoutId
         });
         const listeningRequestsForThisTurn = Object.values(listeningRequests).filter(
            (listeningRequest?: R.ListeningRequest.ListeningRequest) => listeningRequest?.turnId === targetTurn.id && listeningRequest?.void === false
         );
         // return participants who are not a listener in listenerRequestsForThisTurn, and they are in participantsNoTurn
         const availableListeners = participantsNoTurn.filter(
            (participant) =>
               // If we cannot find a lr, then return false, if we can find a lr, then return true
               !listeningRequestsForThisTurn.find((listeningRequest) => listeningRequest?.listenerIdentity === participant)
         );

         // return participants who have a turn in the activeRound, but do not have a listening request for this turnId
         const availableFinalListeners = remoteParticipants.filter(
            (participant) =>
               // If we cannot find a lr, then return false, if we can find a lr, then return true
               !listeningRequestsForThisTurn.find((listeningRequest) => listeningRequest?.listenerIdentity === participant) &&
               !participantsNoTurn.find((participantNoTurn) => participantNoTurn === participant)
         );

         const afl = participantsNoTurn?.length === 0 ? availableFinalListeners : [];
         let requestSent = listeningRequestsForThisTurn.length > 0 ? true : false;
         return {
            availableListeners,
            availableFinalListeners: afl,
            requestSent,
            listeningRequestsForThisTurn
         };
      }
      return {
         availableListeners: [],
         availableFinalListeners: [],
         requestSent: false,
         listeningRequestsForThisTurn: []
      };
   }
);

// Export the customized selectors for this adapter using `getSelectors`
export const {
   selectAll: selectAllListeningRequest,
   selectById: selectListeningRequestById,
   selectIds: selectListeningRequestIds,
   selectEntities: selectListeningRequestEntities

   // Pass in a selector that returns the posts slice of state
} = listeningRequestAdapter.getSelectors<IRootState>((state) => state.listeningRequest);

export const listeningRequestActions = listeningRequestSlice.actions;
