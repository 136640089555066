import {
   PayloadAction,
   createEntityAdapter,
   createSelector,
   createSlice
   // omit other imports
} from "@reduxjs/toolkit";
import { R } from "app/(platform)/meeting-room/_types";
import ILog from "app/_lib/global/Log";
import { selectMainBreakout } from "./breakoutSlice";
import { IRootState } from "app/_contexts/ReduxProvider";

const agendaItemAdapter = createEntityAdapter({
   selectId: (agendaItem: R.AgendaItem.AgendaItem) => agendaItem.id,
   // The sortComparer automatically sorts the agenda items by their sort property, so we do not need to invoke it.
   sortComparer: (a, b) => {
      if (a?.created && b?.created) {
         return a.created - b.created;
      } else {
         return 0;
      }
   }
});

const initialState = agendaItemAdapter.getInitialState({
   status: "idle",
   error: null
});

export const agendaItemSlice = createSlice({
   name: "agendaItem",
   initialState,
   reducers: {
      updateAgendaItem(state, action: PayloadAction<R.AgendaItem.UpdateOne>) {
         const { id, title, sort, description, itemComplete, itemActive, itemPaused, presenterTime, totalTime, breakoutId } = action.payload;
         const existingAgendaItem = state.entities[id];
         if (existingAgendaItem) {
            existingAgendaItem.title = title;
            existingAgendaItem.sort = sort;
            existingAgendaItem.description = description;
            existingAgendaItem.itemComplete = itemComplete;
            existingAgendaItem.itemActive = itemActive;
            existingAgendaItem.itemPaused = itemPaused;
            existingAgendaItem.presenterTime = presenterTime;
            existingAgendaItem.totalTime = totalTime;
            existingAgendaItem.breakoutId = breakoutId;
            existingAgendaItem.empathyCafeConfig = action.payload.empathyCafeConfig;
            existingAgendaItem.empathyCafe = action.payload.empathyCafe;
            existingAgendaItem.void = action.payload.void;
            existingAgendaItem.updated = action.payload.updated;
            existingAgendaItem.clonedFrom = action.payload.clonedFrom;
         } else if (action?.payload?.fallback) {
            agendaItemActions.addAgendaItem(action.payload.fallback);
         }
      },
      addAgendaItem: agendaItemAdapter.addOne,
      updateOneAgendaItem: agendaItemAdapter.updateOne,
      removeAgendaItem: agendaItemAdapter.removeOne,
      addAgendaItems: agendaItemAdapter.addMany,
      updateManyAgendaItems: agendaItemAdapter.updateMany,
      removeManyAgendaItems: agendaItemAdapter.removeMany,
      upsertAgendaItem: agendaItemAdapter.upsertOne,
      upsertAgendaItems: agendaItemAdapter.upsertMany,
      removeAllAgendaItems: agendaItemAdapter.removeAll,
      setAllAgendaItems: agendaItemAdapter.setAll,
      setOneAgendaItem: agendaItemAdapter.setOne,
      setManyAgendaItems: agendaItemAdapter.setMany,

      pauseAgendaItem(state, action: PayloadAction<R.AgendaItem.IdOnly>) {
         const id = action.payload.id;
         const existingAgendaItem = state.entities[id];
         if (existingAgendaItem) {
            existingAgendaItem.itemPaused = true;
         } else if (action?.payload?.fallback) {
            agendaItemActions.addAgendaItem(action.payload.fallback);
         }
      },
      resumeAgendaItem(state, action: PayloadAction<R.AgendaItem.IdOnly>) {
         const id = action.payload.id;
         const existingAgendaItem = state.entities[id];
         if (existingAgendaItem) {
            existingAgendaItem.itemPaused = false;
         } else if (action?.payload?.fallback) {
            agendaItemActions.addAgendaItem(action.payload.fallback);
         }
      },
      startAgendaItem(state, action: PayloadAction<R.AgendaItem.IdOnly>) {
         const id = action.payload.id;
         const existingAgendaItem = state.entities[id];
         if (existingAgendaItem) {
            existingAgendaItem.itemActive = true;
            existingAgendaItem.itemPaused = false;
         } else if (action?.payload?.fallback) {
            agendaItemActions.addAgendaItem(action.payload.fallback);
         }
      },
      endAgendaItem(state, action: PayloadAction<R.AgendaItem.IdOnly>) {
         const id = action.payload.id;
         const existingAgendaItem = state.entities[id];
         if (existingAgendaItem) {
            existingAgendaItem.itemComplete = true;
            existingAgendaItem.itemActive = false;
            existingAgendaItem.itemPaused = false;
         } else if (action?.payload?.fallback) {
            agendaItemActions.addAgendaItem(action.payload.fallback);
         }
      }
   }
});

export const selectActiveAgendaItem = createSelector(
   // return the round that is active
   (state: IRootState) => selectAllAgendaItems(state),
   (_: IRootState, breakoutId?: string) => breakoutId,
   (agendaItem, breakoutId) => {
      return Object.values(agendaItem).find((agendaItem) => agendaItem?.itemActive && agendaItem?.breakoutId === breakoutId);
   }
);

export const selectNextAgendaItem = createSelector(
   // return the agendaItem that is not active
   (state: IRootState) => selectAllAgendaItems(state),
   (_: IRootState, breakoutId?: string) => breakoutId,
   (agendaItems, breakoutId) => {
      return Object.values(agendaItems).find((agendaItem) => !agendaItem?.itemActive && !agendaItem?.itemComplete && agendaItem?.breakoutId === breakoutId);
   }
);

export const selectAgendaItemsByBreakoutId = createSelector(
   (state: IRootState) => selectAllAgendaItems(state),
   (_: IRootState, breakoutId?: string) => breakoutId,
   (agendaItems, breakoutId) => {
      return Object.values(agendaItems).filter((agendaItem) => agendaItem?.breakoutId === breakoutId);
   }
);

export const selectAgendaItemsByClonedFrom = createSelector(
   (state: IRootState) => selectAllAgendaItems(state),
   (_: IRootState, props: { clonedFrom: string; breakoutId: string }) => props,
   (agendaItems, props) => {
      ILog.v(`selectAgendaItemsByClonedFrom:`, { agendaItems, props });
      return Object.values(agendaItems).filter((agendaItem) => agendaItem?.clonedFrom === props.clonedFrom && agendaItem?.breakoutId === props.breakoutId);
   }
);

export const selectIncompleteAgendaItemsMainBreakout = createSelector(
   (state: IRootState) => selectAllAgendaItems(state),
   (state: IRootState) => selectMainBreakout(state),
   (agendaItems, mainBreakout) => {
      return Object.values(agendaItems).filter((agendaItem) => agendaItem?.breakoutId === mainBreakout?.id && !agendaItem?.void && !agendaItem?.itemComplete);
   }
);

export const selectAgendaItemStatus = createSelector(
   (state: IRootState) => selectAllAgendaItems(state),
   (_: IRootState, agendaItemId?: string) => agendaItemId,
   (agendaItems, agendaItemId) => {
      const item = Object.values(agendaItems).find((agendaItem) => agendaItem?.id === agendaItemId);
      const { itemComplete, itemActive, itemPaused } = item || {};
      const status = itemComplete ? "Complete" : itemActive ? "Active" : itemActive && itemPaused ? "Paused" : "Incomplete";
      return status;
   }
);

/* export const selectAgendaItemsByTimeSort = createSelector(
  // return the first turn that matches the speakerIdentity, and is in the active round
  (state: RootState) => state.agendaItem.entities,
  (agendaItems) => {
    const validAgendaItems = Object.values(agendaItems).filter(
      (agendaItem) => agendaItem.sort !== null
    );
    return Object.values(agendaItems).sort((a, b) => {
      return a.timestamp - b.timestamp;
    });
  }
); */

export const agendaItemActions = agendaItemSlice.actions;

// Export the customized selectors for this adapter using `getSelectors`
export const {
   selectAll: selectAllAgendaItems,
   selectById: selectAgendaItemById,
   selectIds: selectAgendaItemIds,
   selectEntities: selectAgendaItemEntities
   //  sortComparer: selectAgendaItemsByTimeSort
   // Pass in a selector that returns the posts slice of state
} = agendaItemAdapter.getSelectors<IRootState>((state) => state.agendaItem);
