import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

export type AppState = {
   leftNavOpen: boolean;
   leftNavPx: number;
   topNavOpen: boolean;
   topNavPx: number;
   bottomNavOpen: boolean;
   directoryNavOpen: boolean;
   breakoutNavOpen: boolean;
   chatNavOpen: boolean;
   agendaNavOpen: boolean;
   agendaControlNavOpen: boolean;
   meetingControlNavOpen: boolean;
   participantNavOpen: boolean;
   listeningRequestNavOpen: boolean;
   isDragging: boolean;
};
const initialState: AppState = {
   leftNavOpen: false,
   leftNavPx: 400,
   topNavOpen: true,
   topNavPx: 400,
   bottomNavOpen: true,
   directoryNavOpen: true,
   breakoutNavOpen: false,
   chatNavOpen: false,
   agendaNavOpen: false,
   agendaControlNavOpen: false,
   meetingControlNavOpen: false,
   participantNavOpen: false,
   listeningRequestNavOpen: false,
   isDragging: false
};

export const appSlice = createSlice({
   name: "app",
   initialState,
   reducers: {
      setAllMenus: (
         state,
         action: PayloadAction<{
            leftNavOpen?: boolean;
            directoryNavOpen?: boolean;
            breakoutNavOpen?: boolean;
            meetingControlNavOpen?: boolean;
            listeningRequestNavOpen?: boolean;
            participantNavOpen?: boolean;
            agendaNavOpen?: boolean;
            agendaControlNavOpen?: boolean;
            chatNavOpen?: boolean;
         }>
      ) => {
         state.leftNavOpen = action.payload.leftNavOpen ?? false;
         state.directoryNavOpen = action.payload.directoryNavOpen ?? false;
         state.breakoutNavOpen = action.payload.breakoutNavOpen ?? false;
         state.meetingControlNavOpen = action.payload.meetingControlNavOpen ?? false;
         state.listeningRequestNavOpen = action.payload.listeningRequestNavOpen ?? false;
         state.participantNavOpen = action.payload.participantNavOpen ?? false;
         state.agendaNavOpen = action.payload.agendaNavOpen ?? false;
         state.agendaControlNavOpen = action.payload.agendaControlNavOpen ?? false;
         state.chatNavOpen = action.payload.chatNavOpen ?? false;
      },
      setTopNavOpen: (state, action: PayloadAction<boolean>) => {
         state.topNavOpen = action.payload;
      },

      setTopNavPx: (state, action: PayloadAction<number>) => {
         state.topNavPx = action.payload;
      },
      setLeftNavPx: (state, action: PayloadAction<number>) => {
         state.leftNavPx = action.payload;
      },
      setBottomNavOpen: (state, action: PayloadAction<boolean>) => {
         state.bottomNavOpen = action.payload;
      },
      setLeftNavOpen: (state, action: PayloadAction<boolean>) => {
         state.leftNavOpen = action.payload;
      },
      setIsDragging: (state, action: PayloadAction<boolean>) => {
         state.isDragging = action.payload;
      }
   }
});

export const selectLeftNavHasItems = createSelector([(appState: AppState, override?: boolean) => appState, (_: AppState, override?: boolean) => override], (appState, override) => {
   if (override === true) return true;
   return appState.leftNavOpen || appState.directoryNavOpen;
});

export const appActions = appSlice.actions;
